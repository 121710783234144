import { fetchData } from "utils/networking/fetchData";
import { postData } from "utils/networking/postData";
import { RadiologySite, RawStudyImageData } from "models/study.types";
import { StudyData } from "pages/home-page/components/study-table/studyTable.types";
import { formatFilterData } from "./functions/formatFilterData";

export const getDicomImageAnnotations = async (
  studyId: string,
  seriesId: string,
  instanceId: string
): Promise<null | string> => {
  const FETCH_ANNOTATIONS_URL = `patients/get-annotations/${studyId}/${seriesId}/${instanceId}`;
  const result = await fetchData(FETCH_ANNOTATIONS_URL);
  return result;
};

export const saveDicomImageAnnotations = async (
  annotationData: string,
  studyId: string,
  seriesId: string,
  instanceId: string
) => {
  const ADD_ANNOTATIONS_URL = `patients/save-annotations/${studyId}/${seriesId}/${instanceId}`;
  const result = await postData({
    uri: ADD_ANNOTATIONS_URL,
    body: { annotationData },
  });
  return result;
};

export const fetchStudyTableRowData = async (
  userId: string,
  page: number,
  sortColumn: string,
  sortDirection: string,
  filterData: Record<string, any> = {},
  fetchCount?: boolean
): Promise<StudyData[] | { count: string }> => {
  const PAGINATED_STUDIES_URL = `study/search-studies`;
  const formattedFilterData = formatFilterData(filterData);

  const result = await postData({
    uri: PAGINATED_STUDIES_URL,
    body: {
      userId,
      page,
      sortColumn,
      sortDirection,
      filterData: formattedFilterData,
      fetchCount,
    },
    convertToCamel: true,
  });
  if (result.errorMessage) {
    return [];
  } else {
    return result as StudyData[] | { count: string };
  }
};

export const updateStudyValues = async (
  studypk: string,
  updatedFields: Record<string, string | boolean | number>
) => {
  const UPDATE_STUDY_URL = "patients/update-study";
  const result = await postData({
    uri: UPDATE_STUDY_URL,
    body: {
      studypk,
      updatedFields,
    },
  });
  return result as { success: boolean; message: string };
};

export const getSeriesByStudyPk = async (studyPk: string) => {
  const URL = `patients/seriesbystudypk/${studyPk}`;
  const result = await fetchData(URL);
  return result;
};

export async function getTypeByStudyPk(pk) {
  const URL = `patients/typebystudypk/${pk}`;
  const result = await fetchData(URL);
  return result;
}

export async function getStudyDatetimeByPk(pk) {
  const URL = `patients/studydatetimebypk/${pk}`;
  const result = await fetchData(URL);
  return result;
}

export async function getStudyByPk(pk) {
  const URL = `patients/studybypk/${pk}`;
  const result = await fetchData(URL);
  return result;
}

export const getInstitutionTemplate = async (
  institutionName: string | null
): Promise<RadiologySite | null> => {
  if (institutionName === null) {
    console.warn(
      "No institution site name for this patient - not retrieving site info"
    );
    return null;
  }
  const uri = `patients/institution-template`;
  const result = await postData({
    uri,
    body: { institutionName },
    convertToCamel: true,
  });
  const { institutionTemplate } = result as {
    institutionTemplate: RadiologySite[] | null[];
  };
  return institutionTemplate[0] ?? null;
};

export async function getInstanceBySeriesPk(pk) {
  const URL = `patients/instancebyseriespk/${pk}`;
  const result = await fetchData(URL);
  return result;
}

export const fetchStudyArray = async (studyPk: string, studyIuid: string) => {
  const STUDY_ARRAY_URL = `study/study-images`;
  const result = await postData({
    uri: STUDY_ARRAY_URL,
    body: { studyPk, studyIuid },
    convertToCamel: true,
  });
  return result as RawStudyImageData[];
};
