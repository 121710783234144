export const TOOL_ICON_STYLE = { width: 24, height: 24 };
export const TOOL_MODES = {
  DISABLED: "disabled",
  ACTIVE: "active",
  PASSIVE: "passive",
};

export const MOUSE_BUTTON_KEYS = {
  LEFT: 1,
  RIGHT: 2,
  BOTH: 3,
  WHEEL: 4,
};

export const TOOL_IDS = {
  ERASER: "Eraser",
  TRUE_VERTICAL: "TrueVertical",
  TRUE_HORIZONTAL: "TrueHorizontal",
  STRAIGHT_LINE: "StraightLine",
  CLEAR_ANNOTATIONS: "ClearAnnotations",
  SAVE_ANNOTATIONS: "SaveAnnotations",
  COBB_ANGLE: "CobbAngle",
  ANGLE: "Angle",
  POINTER: "Pointer",
  VFLIP: "VFlip",
  HFLIP: "HFlip",
  INVERT: "Invert",
  RECTANGULAR_AREA: "RectangularArea",
  ELLIPTICAL_AREA: "EllipticalArea",
  LENGTH_TOOL: "LengthTool",
  CENTER_LINE: "CenterLine",
  CURVE_RADIUS: "CurveRadius",
  CENTER_POINT: "CenterPoint",
  DARA: "Dara",
  HIP_DISTRACTION_INDEX: "HipDistractionIndex",
  VERTEBRAL_HEART_SCORE: "VertebralHeartScore",
  CERVICAL_LABEL: "CervicalLabel",
  THORATIC_LABEL: "ThoraticLabel",
  LUMBAR_LABEL: "LumbarLabel",
  GEORGES_LINE: "GeorgesLine",
  GONSTEAD_TOOL: "GonsteadTool",
  WWWC: "Wwwc",
  ZOOM_MOUSE_WHEEL: "ZoomMouseWheel",
  MAGNIFY: "Magnify",
  ARROW_ANNOTATE: "ArrowAnnotate",
  PAN: "Pan",
  PAN_MULTI_TOUCH: "PanMultiTouch",
  ZOOM_TOUCH_PINCH: "ZoomTouchPinch",
  STACK_SCROLL_MOUSE_WHEEL: "StackScrollMouseWheel",
  STACK_SCROLL_MULTI_TOUCH: "StackScrollMultiTouch",
  ZOOM: "Zoom",
  FREE_HAND_AREA: "FreeHandArea",
  PLAY_FRAMES: "PlayFrames",
  PAUSE_FRAMES: "PauseFrames",
  MPR_TOOL: "MPRTool",

  PIXEL_PROBE: "Probe",
};
export const CONTEXT_FUNCTION_TOOLS = [TOOL_IDS.MPR_TOOL];
export const AUTO_PERFORM_TOOLS = [
  TOOL_IDS.CLEAR_ANNOTATIONS,
  TOOL_IDS.SAVE_ANNOTATIONS,
  TOOL_IDS.INVERT,
  TOOL_IDS.HFLIP,
  TOOL_IDS.VFLIP,
];
export const TOOL_ICON_PATH = "../assets/images/icons/tool-icons/";
export const TOOL_BAR_CATEGORIES = {
  MEASUREMENT: "Measurement",
  SPINAL_LABELS: "Spinal Labels",
  MANIPULATION: "Manipulation",
  ANNOTATION: "Annotation",
  ERASE: "erase",
  FRAMED_MODALITIES: "Framed Modalities",
};
export const REQUIRES_PARSED_METADATA = [
  TOOL_IDS.MPR_TOOL,
  TOOL_IDS.LENGTH_TOOL,
  TOOL_IDS.RECTANGULAR_AREA,
  TOOL_IDS.ELLIPTICAL_AREA,
  TOOL_IDS.FREE_HAND_AREA,
  TOOL_IDS.CURVE_RADIUS,
  TOOL_IDS.HIP_DISTRACTION_INDEX,
  TOOL_IDS.DARA,
  TOOL_IDS.GEORGES_LINE,
  TOOL_IDS.VERTEBRAL_HEART_SCORE,
  TOOL_IDS.GONSTEAD_TOOL,
  TOOL_IDS.COBB_ANGLE,
  TOOL_IDS.ANGLE,
];

export const DEFAULT_ACTIVE_TOOLS = [
  TOOL_IDS.PAN,
  TOOL_IDS.ZOOM,
  TOOL_IDS.WWWC,
];
export const DEFAULT_TOOLS = [
  {
    name: TOOL_IDS.PAN,
    mode: "active",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.WHEEL },
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
  {
    name: TOOL_IDS.ZOOM,
    mode: "active",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.RIGHT },
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
  {
    name: TOOL_IDS.WWWC,
    mode: "active",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
];

export const MPR_TOOL_CONFIG = {
  name: TOOL_IDS.MPR_TOOL,
  mode: "active",
  label: "MPR",
  iconFileName: "mpr-icon",
  modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
  toolbarCategory: TOOL_BAR_CATEGORIES.FRAMED_MODALITIES,
};

export const COMMON_MANIPULATION_TOOLS = [
  {
    name: TOOL_IDS.PLAY_FRAMES,
    mode: "disabled",
    label: "Play",
    iconFileName: "play-frames-icon",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    toolbarCategory: TOOL_BAR_CATEGORIES.FRAMED_MODALITIES,
  },
  {
    name: TOOL_IDS.PAUSE_FRAMES,
    mode: "disabled",
    label: "Pause",
    iconFileName: "pause-frames-icon",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    toolbarCategory: TOOL_BAR_CATEGORIES.FRAMED_MODALITIES,
  },
  MPR_TOOL_CONFIG,
  {
    name: TOOL_IDS.ZOOM_MOUSE_WHEEL,
    mode: "disabled",
    modeOptions: { mouseButtonMask: 3 },
    iconFileName: "zoom-in-icon",
    label: "Scroll to Zoom",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
  {
    name: "StackScrollMouseWheel",
    mode: "disabled",
    modeOptions: { mouseButtonMask: 4 },
    iconFileName: "stack-scroll-icon",
    label: "Scroll to Swap Images",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
  {
    name: "Magnify",
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "magnifying-icon",
    label: "Magnify Tool",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
  {
    name: "ArrowAnnotate",
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "text-annotation-icon",
    label: "Text Label",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },
  {
    name: TOOL_IDS.TRUE_VERTICAL,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "true-vertical-icon",
    label: "True Vertical",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },
  {
    name: TOOL_IDS.TRUE_HORIZONTAL,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "true-horizontal-icon",
    label: "True Horizontal",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },
  {
    name: TOOL_IDS.STRAIGHT_LINE,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "straight-line-icon",
    label: "Straight Line",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },
  {
    name: "Rotate",
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "rotate-icon",
    label: "Rotate",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
  {
    name: "Brush",
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "highlighter-icon",
    label: "Highlighter",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },
  {
    name: TOOL_IDS.ERASER,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "erase-annotation-icon",
    label: "Annotation Eraser",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "clear-annotations-icon",
    name: TOOL_IDS.CLEAR_ANNOTATIONS,
    label: "Clear All Annotations",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "save-annotation-icon",
    name: TOOL_IDS.SAVE_ANNOTATIONS,
    label: "Save Annotations",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },

  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "vertical-flip-icon",
    name: TOOL_IDS.VFLIP,
    label: "Vertical Flip",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "horizontal-flip-icon",
    name: TOOL_IDS.HFLIP,
    label: "Horizontal Flip",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "invert-icon",
    name: TOOL_IDS.INVERT,
    label: "Invert",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MANIPULATION,
  },
];

export const COMMON_MEASUREMENT_TOOLS = [
  {
    name: TOOL_IDS.ANGLE,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "angle-icon",
    label: "Angle",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
  {
    name: TOOL_IDS.LENGTH_TOOL,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "length-tool-icon",
    label: "Length Tool",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    name: TOOL_IDS.ELLIPTICAL_AREA,
    iconFileName: "elliptical-area-icon",
    label: "Elliptical Area",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    name: TOOL_IDS.RECTANGULAR_AREA,
    iconFileName: "rectangular-area-icon",
    label: "Rectangular Area",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
  {
    name: TOOL_IDS.FREE_HAND_AREA,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "free-hand-area-icon",
    label: "Freehand Area",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
  {
    name: TOOL_IDS.PIXEL_PROBE,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "pixel-probe-icon",
    label: "Pixel Probe",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
];

export const MEDICAL_TOOLS = [
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "center-line-icon",
    name: TOOL_IDS.CENTER_LINE,
    label: "Center Line",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.ANNOTATION,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "curve-radius-icon",
    name: TOOL_IDS.CURVE_RADIUS,
    label: "Curve Radius",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "center-point-icon",
    name: TOOL_IDS.CENTER_POINT,
    label: "Center Point",
    type: "medical",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
  {
    name: TOOL_IDS.COBB_ANGLE,
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "cobb-angle-icon",
    label: "Cobb Angle",
    type: "chiro",
    toolbarCategory: TOOL_BAR_CATEGORIES.MEASUREMENT,
  },
];

export const VET_TOOLS = [
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "dara-icon",
    name: TOOL_IDS.DARA,
    label: "DARA",
    type: "vet",
    toolbarCategory: "annotation",
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "hip-distraction-icon",
    name: TOOL_IDS.HIP_DISTRACTION_INDEX,
    label: "Hip Distraction Index",
    type: "vet",
    toolbarCategory: "annotation",
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "vhs-icon",
    name: TOOL_IDS.VERTEBRAL_HEART_SCORE,
    label: "Vertebral Heart Score",
    type: "vet",
    toolbarCategory: "annotation",
  },
];

export const CHIRO_TOOLS = [
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "cervical-icon",
    name: TOOL_IDS.CERVICAL_LABEL,
    label: "Cervical Label",
    type: "chiro",
    toolbarCategory: TOOL_BAR_CATEGORIES.SPINAL_LABELS,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "thoratic-icon",
    name: TOOL_IDS.THORATIC_LABEL,
    label: "Thorax Label",
    type: "chiro",
    toolbarCategory: TOOL_BAR_CATEGORIES.SPINAL_LABELS,
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "lumbaric-icon",
    name: TOOL_IDS.LUMBAR_LABEL,
    label: "Lumbaric Label",
    type: "chiro",
    toolbarCategory: TOOL_BAR_CATEGORIES.SPINAL_LABELS,
  },

  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "georges-line-icon",
    name: TOOL_IDS.GEORGES_LINE,
    label: "Georges Line",
    type: "chiro",
    toolbarCategory: "annotation",
  },
  {
    mode: "disabled",
    modeOptions: { mouseButtonMask: MOUSE_BUTTON_KEYS.LEFT },
    iconFileName: "gonstead-icon",
    name: TOOL_IDS.GONSTEAD_TOOL,
    label: "Gonstead",
    type: "chiro",
    toolbarCategory: "annotation",
  },
];
export const DENTAL_TOOLS = [];

export const ALL_TOOLS = [
  ...COMMON_MANIPULATION_TOOLS,
  ...COMMON_MEASUREMENT_TOOLS,
  ...MEDICAL_TOOLS,
  ...DENTAL_TOOLS,
  ...CHIRO_TOOLS,
  ...VET_TOOLS,
];

export const MEASUREMENT_SUFFIXES = {
  MM: "mm",
  MM2: `mm${String.fromCharCode(178)}`,
  PIXELS: "px",
};

export const DEFAULT_MEASUREMENT_DECIMALS = 2;
export const ANNOTATION_TEXT_X_OFFSET_PX = 10;

export const EVENTS = {
  MOUSE_DOWN: "cornerstonetoolsmousedown",
  MOUSE_UP: "cornerstonetoolsmouseup",
  MOUSE_DOWN_ACTIVATE: "cornerstonetoolsmousedownactivate",
  MOUSE_DRAG: "cornerstonetoolsmousedrag",
  MOUSE_MOVE: "cornerstonetoolsmousemove",
  MOUSE_CLICK: "cornerstonetoolsmouseclick",
  MOUSE_DOUBLE_CLICK: "cornerstonetoolsmousedoubleclick",
  MOUSE_WHEEL: "cornerstonetoolsmousewheel",
  TOUCH_START: "cornerstonetoolstouchstart",
  TOUCH_START_ACTIVE: "cornerstonetoolstouchstartactive",
  TOUCH_END: "cornerstonetoolstouchend",
  TOUCH_DRAG: "cornerstonetoolstouchdrag",
  TOUCH_DRAG_END: "cornerstonetoolstouchdragend",
  TOUCH_PINCH: "cornerstonetoolstouchpinch",
  TOUCH_ROTATE: "cornerstonetoolstouchrotate",
  TOUCH_PRESS: "cornerstonetoolstouchpress",
  TAP: "cornerstonetoolstap",
  DOUBLE_TAP: "cornerstonetoolsdoubletap",
  MULTI_TOUCH_START: "cornerstonetoolsmultitouchstart",
  MULTI_TOUCH_START_ACTIVE: "cornerstonetoolsmultitouchstartactive",
  MULTI_TOUCH_DRAG: "cornerstonetoolsmultitouchdrag",
  KEY_DOWN: "cornerstonetoolskeydown",
  KEY_UP: "cornerstonetoolskeyup",
  KEY_PRESS: "cornerstonetoolskeypress",
  MEASUREMENT_ADDED: "cornerstonetoolsmeasurementadded",
  MEASUREMENT_MODIFIED: "cornerstonetoolsmeasurementmodified",
  MEASUREMENT_COMPLETED: "cornerstonetoolsmeasurementcompleted",
  MEASUREMENT_REMOVED: "cornerstonetoolsmeasurementremoved",
  TOOL_DEACTIVATED: "cornerstonetoolstooldeactivated",
  CLIP_STOPPED: "cornerstonetoolsclipstopped",
  STACK_SCROLL: "cornerstonetoolsstackscroll",

  STACK_PREFETCH_IMAGE_LOADED: "cornerstonetoolsstackprefetchimageloaded",
  STACK_PREFETCH_DONE: "cornerstonetoolsstackprefetchdone",
  LABELMAP_MODIFIED: "cornersontetoolslabelmapmodified",
};

export const DIRECTIONS = {
  FORWARD: "forward",
  BACKWARD: "backward",
};

export const INITIAL_RENDER_TOOLS = [
  TOOL_IDS.LENGTH_TOOL,
  TOOL_IDS.RECTANGULAR_AREA,
  TOOL_IDS.ELLIPTICAL_AREA,
  TOOL_IDS.ANGLE,
  TOOL_IDS.COBB_ANGLE,
  TOOL_IDS.CURVE_RADIUS,
  TOOL_IDS.STRAIGHT_LINE,
  TOOL_IDS.TRUE_HORIZONTAL,
  TOOL_IDS.TRUE_VERTICAL,
  TOOL_IDS.CENTER_LINE,
  TOOL_IDS.CENTER_POINT,
  TOOL_IDS.FREE_HAND_AREA,
];

export const NON_ERASER_TOOLS = [TOOL_IDS.ERASER, ...DEFAULT_ACTIVE_TOOLS];
