import { useInitializeTools } from "./useInitializeTools";
import { useActiveTools } from "./useActiveTools";
import { useParseImageMetadataOnLoad } from "./useParseImageMetadataOnLoad";

export const useTools = () => {
  const { addToolCallback, initializedTools } = useInitializeTools();
  const { onToolButtonClick } = useActiveTools(initializedTools);
  useParseImageMetadataOnLoad();
  return {
    tools: initializedTools,
    addToolCallback,
    onToolButtonClick,
  };
};
