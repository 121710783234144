import { getColourByUserType } from "../utils/colors/getColorByUserType";
import { useMemo, useEffect } from "react";
import { useAppContext } from "context/app.context";
import { setFaviconAndBackgroundColors } from "utils/colors/setFaviconAndBackgroundColors";
import { getUserColorClass } from "utils/colors/getUserColorClass";

export const useUserType = () => {
  const {
    state: { userType: appUserType },
  } = useAppContext();

  const userType = useMemo(() => appUserType, [appUserType]);

  useEffect(() => {
    setFaviconAndBackgroundColors(userType);
  }, [userType]);

  return useMemo(
    () => ({
      userType,
      userTypeColour: getColourByUserType(userType),
      userColorClass: getUserColorClass(userType),
    }),
    [userType]
  );
};
