import { DarkModeSwitch } from "components/DarkmodeSwitch/DarkmodeSwitch";
import { useUserType } from "hooks/useUserType";
import { UserInfo } from "./components/UserInfo";
import { LogoutButton } from "./components/LogoutButton";
import { HomeIcon } from "./components/HomeIcon";

export const AppHeader = () => {
  const { userColorClass } = useUserType();
  return (
    <div className="app-header">
      <div className={`header-container ${userColorClass}`}>
        <h1>
          <HomeIcon /> iTX Cloud PACS
        </h1>
        <span className="header-subtitle">
          The Only PACS Developed in Australia.
        </span>
      </div>
      <div className="app-controls">
        <UserInfo />
        <LogoutButton />
        <DarkModeSwitch />
      </div>
    </div>
  );
};
