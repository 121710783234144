import { useCallback } from "react";
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import {
  AUTO_PERFORM_TOOLS,
  DEFAULT_ACTIVE_TOOLS,
  DEFAULT_TOOLS,
  TOOL_IDS,
} from "../consts/tools.consts";
import { MIN_CLIENT_WIDTH_PX } from "pages/viewer/dicomViewer.consts";
import { getFormattedModeOption } from "../functions/formatModeOptions";
import { ToolConfig } from "../tools.types";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useAutoPerform } from "./useAutoPerform";
import { useInitialPerform } from "./useInitialPerform";
import { useDeactivationPerform } from "./useDeactivationPerform";

export const useActiveTools = (toolConfig: ToolConfig[]) => {
  const {
    dispatch,
    state: { activeTools: activeToolIds },
  } = useViewerContext();
  const autoPerform = useAutoPerform();
  const initialPerform = useInitialPerform();
  const onDeactivation = useDeactivationPerform();
  const onToolButtonClick = useCallback(
    (clickedToolId: string) => {
      const selectedToolId = activeToolIds.includes(clickedToolId)
        ? null
        : clickedToolId;

      const activeTools = [];
      const enabledElementsArray = cornerstone.getEnabledElements();
      if (clickedToolId === TOOL_IDS.MPR_TOOL) {
        const isMPRActive = !!selectedToolId;
        dispatch({
          type: "TOGGLE_MPR_ACTIVE",
          payload: { isActive: isMPRActive },
        });
      }
      enabledElementsArray.forEach((enabledElement) => {
        if (selectedToolId === null) {
          onDeactivation(clickedToolId, enabledElement);
          const activeDefaultTools = deselectTool(
            clickedToolId,
            toolConfig,
            enabledElement.element
          );
          activeTools.push(...activeDefaultTools);
        } else {
          // we don't need to set auto performing tools to active/inactive - because they happen then are done
          // They aren't toggled states
          if (!AUTO_PERFORM_TOOLS.includes(selectedToolId)) {
            activeTools.push(selectedToolId);
          }

          if (enabledElement.element.clientWidth > MIN_CLIENT_WIDTH_PX) {
            if (AUTO_PERFORM_TOOLS.includes(selectedToolId)) {
              autoPerform(selectedToolId, enabledElement);
            } else {
              const activeDefaultIds = selectToolAndDeselectDefault(
                selectedToolId,
                toolConfig,
                enabledElement.element
              );
              activeTools.push(...activeDefaultIds);
              initialPerform(selectedToolId, enabledElement);
            }
          }
        }
      });

      const newlySetActiveToolIds = [...new Set(activeTools)];
      dispatch({
        type: "SET_ACTIVE_TOOLS",
        payload: { activeTools: newlySetActiveToolIds },
      });
    },
    [activeToolIds, toolConfig, dispatch, onDeactivation]
  );

  return { onToolButtonClick };
};

export const deselectTool = (
  idToDisable: string,
  toolConfigs: ToolConfig[],
  element: HTMLElement
) => {
  const toolToDisable = toolConfigs.find(({ name }) => idToDisable === name);
  const enabledDefaultToolIds = DEFAULT_TOOLS.map(({ name }) => name);
  if (toolToDisable) {
    const { name, modeOptions } = toolToDisable;
    const { mouseButtonMask } = getFormattedModeOption(modeOptions);
    cornerstoneTools.setToolPassiveForElement(element, name, {
      mouseButtonMask: null,
    });
    DEFAULT_TOOLS.forEach((tool) => {
      const { name: toolName, modeOptions } = tool;
      const formatted = getFormattedModeOption(modeOptions);
      if (formatted.mouseButtonMask === mouseButtonMask) {
        toggleDefaultToolsActive(toolName, formatted, element, true);
      }
    });
  }
  return enabledDefaultToolIds;
};

export const selectToolAndDeselectDefault = (
  selectedToolId: string,
  toolConfigs: ToolConfig[],
  element: HTMLElement
) => {
  const activeDefaultToolIds = [];
  const toolToEnable = toolConfigs.find(({ name }) => selectedToolId === name);
  if (toolToEnable) {
    const { name, modeOptions } = toolToEnable;
    const { mouseButtonMask } = getFormattedModeOption(modeOptions);
    DEFAULT_TOOLS.forEach((tool) => {
      const { name: defaultToolName, modeOptions } = tool;
      const formatted = getFormattedModeOption(modeOptions);
      if (formatted.mouseButtonMask === mouseButtonMask) {
        toggleDefaultToolsActive(defaultToolName, formatted, element, false);
      } else {
        activeDefaultToolIds.push(defaultToolName);
      }
    });
    cornerstoneTools.setToolActiveForElement(element, name, {
      mouseButtonMask,
    });
  }
  return activeDefaultToolIds;
};

export const toggleDefaultToolsActive = (
  name: string,
  modeOptions,
  element,
  toggleActive = true
) => {
  if (DEFAULT_ACTIVE_TOOLS.includes(name)) {
    if (toggleActive) {
      cornerstoneTools.setToolActiveForElement(element, name, modeOptions);
    } else {
      cornerstoneTools.setToolPassiveForElement(element, name, {
        mouseButtonMask: null,
      });
    }
  }
};
