import cornerstoneTools from "cornerstone-tools";
import { ImageMetadata } from "pages/viewer/dicomViewer.types";
import Util from "./Util";
import Drawing from "./Drawing";

const BaseAnnotationTool = cornerstoneTools.importInternal(
  "base/BaseAnnotationTool"
);

export class ExtendedAnnotationTool extends BaseAnnotationTool {
  toolId: string;
  imageMetaData: {};
  throttledUpdateCachedStats: any;
  constructor(name) {
    const props = {
      name,
      supportedInteractionTypes: ["Mouse", "Touch"],
    };
    super(props);
    this.toolId = name;
    this.imageMetaData = {};
    this.setImageMetadata = this.setImageMetadata.bind(this);
    this.initialRenderForElement = this.initialRenderForElement.bind(this);
    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
  }
  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetaData = imageMetaData;
    }
  }

  public initialRenderForElement(element?) {
    if (element) {
      const toolData = cornerstoneTools.getToolState(element, this.toolId);
      const canvas = element.querySelector("canvas");
      const context = Drawing.getNewContext(canvas);
      if (toolData?.data && toolData?.data.length) {
        toolData.data.forEach((data) => {
          this.drawToolData(element, context, data);
        });
      }
    } else {
      console.warn(`Element not passed into ${this.toolId}`);
    }
  }

  updateCachedStats(_image, _element, _data) {
    console.warn(`Update cached stats not configured for ${this.toolId}`);
  }

  updateCachedStatsOnRender(image, element, toolData) {
    if (toolData && toolData.data) {
      toolData.data.forEach((data) => {
        if (data.invalidated === true) {
          if (data.length && !data.noThrottle) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }
      });
    }
  }

  renderToolData = ({
    currentTarget,
    detail: { element, image, canvasContext },
  }) => {
    const toolData = cornerstoneTools.getToolState(currentTarget, this.toolId);
    const context = Drawing.getNewContext(canvasContext.canvas);
    if (toolData?.data && toolData?.data.length) {
      toolData.data.forEach((data) => {
        this.drawToolData(element, context, data);
      });
    }
    this.updateCachedStatsOnRender(image, element, toolData);
  };

  drawToolData(_element: HTMLElement, _canvas: HTMLCanvasElement, _toolData) {
    console.warn(`Draw tool data method not configured for ${this.toolId}`);
  }
}
