import { ALL_TOOLS } from "../../consts/tools.consts";
import cornerstoneTools from "cornerstone-tools";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { VIEWPORT_STATUSES } from "pages/viewer/dicomViewer.consts";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { saveDicomImageAnnotations } from "services/studies/studies";
import { getViewportIdFromElement } from "../../functions/getViewportIdFromElement";

export const useSaveAnnotation = () => {
  const { dispatch } = useViewerContext();
  return useCallback(async (enabledElement, image) => {
    const viewportIndex = getViewportIdFromElement(enabledElement);
    if (!isNaN(viewportIndex)) {
      dispatch({
        type: "SET_VIEWPORT_STATUS",
        payload: {
          viewportIndex,
          newStatus: VIEWPORT_STATUSES.SAVING_ANNOTATIONS,
        },
      });
      const allTools = ALL_TOOLS;
      const elementToolState = allTools.flatMap((tool) => {
        const toolState = cornerstoneTools.getToolState(
          enabledElement,
          tool.name
        );
        if (toolState) {
          return { toolName: tool.name, toolState };
        }
        console.warn(`no tool state found for ${tool.name}`);
        return [];
      });
      if (elementToolState.length) {
        const { imageId } = image;
        const imageURL = new URL(imageId);
        const searchParams = new URLSearchParams(imageURL.search);
        const studyIUID = searchParams.get("studyUID");
        const seriesIUID = searchParams.get("seriesUID");
        const instanceIUID = searchParams.get("objectUID");
        const stringifiedToolState = JSON.stringify(elementToolState);
        const result = await saveDicomImageAnnotations(
          stringifiedToolState,
          studyIUID,
          seriesIUID,
          instanceIUID
        );
        if (result.errorMessage) {
          toast.error("Unable to save annotations");
        } else {
          toast.success("Annotations successfully saved");
        }
      } else {
        toast.warn("No tool data to save");
      }

      dispatch({
        type: "SET_VIEWPORT_STATUS",
        payload: { viewportIndex, newStatus: VIEWPORT_STATUSES.COMPLETE },
      });
    }
  }, []);
};
