import cornerstone from "cornerstone-core";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { TOOL_IDS } from "../consts/tools.consts";
import { DEFAULT_HANDLE } from "../consts/tools.defaults";

export class CentreLine extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  constructor() {
    super(TOOL_IDS.CENTER_LINE);
    this.hasIncomplete = false;
  }

  createNewMeasurement(event) {
    const goodEventData =
      event && event.currentPoints && event.currentPoints.image;
    if (!goodEventData) {
      return;
    }
    const { x, y } = event.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,

      complete: false, //////////////////

      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          //edit here to draw many points for many lengths between different points
          x,
          y,
          highlight: true,
          active: false,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;
    if (!validParameters) {
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 10;

    if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }
    return line;
  }

  drawToolData(element: HTMLElement, context, toolData: any) {
    const { visible, handles } = toolData;
    if (visible) {
      const textCoordsStart = cornerstone.pixelToCanvas(element, handles.start);
      const textCoordsEnd = cornerstone.pixelToCanvas(element, handles.end);
      const MidX = (textCoordsStart.x + textCoordsEnd.x) / 2;
      const a = textCoordsStart.y;
      const b = textCoordsEnd.y;

      context.beginPath();
      context.fillStyle = "red";
      context.arc(MidX, a, 3, 0, 2 * Math.PI); // Control point two handle 2
      context.arc(MidX, b, 3, 0, 2 * Math.PI);
      context.fill();

      const TopCentre = {
        x: MidX,
        y: a,
      };
      const BotCentre = {
        x: MidX,
        y: b,
      };
      const handleOptions = {
        ...DEFAULT_HANDLE,
        drawHandlesIfActive: true,
        hideHandlesIfMoving: true,
      };
      Drawing.drawHandles(context, { element }, handles, handleOptions);
      Drawing.drawLine(context, element, TopCentre, BotCentre, "red", "canvas");
    }
  }
}
