import { createContext, useReducer, useContext, ReactNode } from "react";
import { DIRECTIONS } from "../tools/consts/tools.consts";
import {
  INITIAL_MPR_COORDINATE,
  INITIAL_VIEWER_GRID_STATE,
  VIEWPORT_STATUSES,
} from "../dicomViewer.consts";
import { formatViewportUpdates } from "../helpers/formatViewportUpdates";
import { setMPRViewports } from "../helpers/setMPRViewports";
import { MPR_STATUSES } from "../tools/mpr-tool/mprTool.consts";
import {
  ViewerActions,
  ViewerContextType,
  ViewerState,
} from "./viewer.context.types";
import { updateFrameIndexes } from "./helpers/updateFrameIndexes";

export const INITIAL_VIEWER_STATE: ViewerState = {
  rawMetaData: [],
  activeTools: [],
  modalityType: null,
  viewportFrameIndexes: [0],
  totalFrames: 1,
  gridDimensions: INITIAL_VIEWER_GRID_STATE,
  viewports: [],
  viewportToolStates: [],
  studyImageMetaData: [],
  viewportStatuses: [VIEWPORT_STATUSES.INITIALIZING],
  mprCoordinates: INITIAL_MPR_COORDINATE,
  studyImageData: null,
  isMPRActive: false,
  axialImages: [],
  coronalImages: new Map(),
  sagittalImages: new Map(),
};

export const ViewerContext = createContext<ViewerContextType | undefined>(
  undefined
);

const reducer = (state: ViewerState, action: ViewerActions) => {
  switch (action.type) {
    case "TOGGLE_MPR_ACTIVE": {
      return {
        ...state,
        isMPRActive: action.payload.isActive,
      };
    }
    case "LOAD_AXIAL_IMAGES": {
      return {
        ...state,
        axialImages: action.payload.axialImages,
        mprCoordinates: {
          x: action.payload.axialImages.length / 2,
          y: action.payload.axialImages.length / 2,
        },
      };
    }
    case "MPR_TOOL_UPDATE": {
      const { x, y } = action.payload;
      return {
        ...state,
        mprCoordinates: { x: Math.round(x), y: Math.round(y) },
      };
    }
    case "SET_MPR_VIEWPORTS": {
      const updatedViewports = setMPRViewports(
        state.viewports,
        state.studyImageData
      );
      const viewportStatuses = state.viewportStatuses.map(
        (viewportStatus, index) =>
          index !== 0 ? VIEWPORT_STATUSES.COMPLETE : viewportStatus
      );
      return {
        ...state,
        viewportStatuses,
        viewports: updatedViewports,
      };
    }
    case "MPR_IMAGE_GENERATED": {
      const { viewports } = state;
      const updatedViewports = viewports.map((viewport, index) => {
        const payloadKey =
          index === 0
            ? "axialImages"
            : index === 1
            ? "coronalImage"
            : "sagittalImage";
        if (payloadKey === "axialImages") {
          return viewport;
        }
        return {
          ...viewport,
          images: [action.payload[payloadKey]],
        };
      });

      return {
        ...state,
        viewportFrameIndexes: [state.viewportFrameIndexes[0], 0, 0],
        viewports: updatedViewports,
        viewportStatuses: [
          state.viewportStatuses[0],
          VIEWPORT_STATUSES.COMPLETE,
          VIEWPORT_STATUSES.COMPLETE,
        ],
      };
    }

    case "SET_STUDY_DATA":
      const initialStudyImageMetaData = action.payload.studyImageData.map(
        () => null
      );
      const initialRawData = action.payload.studyImageData.map(() => null);
      return {
        ...state,
        modalityType: action.payload.modalityType,
        totalFrames: action.payload.totalFrames,
        studyImageData: action.payload.studyImageData,
        studyImageMetaData: initialStudyImageMetaData,
        rawMetaData: initialRawData,
      };
    case "SET_ACTIVE_TOOLS":
      return {
        ...state,
        activeTools: action.payload.activeTools,
      };
    case "INITIALIZE_VIEWPORT": {
      const initialViewport = formatViewportUpdates(
        INITIAL_VIEWER_GRID_STATE,
        state.modalityType,
        state.studyImageData,
        []
      );

      return {
        ...state,
        viewports: initialViewport,
      };
    }
    case "UPDATE_VIEWER_GRID": {
      const { updatedGridDimensions } = action.payload;
      const updatedViewports = formatViewportUpdates(
        updatedGridDimensions,
        state.modalityType,
        state.studyImageData,
        state.viewports
      );
      const updatedStatuses = new Array(
        updatedGridDimensions[0] * updatedGridDimensions[1]
      )
        .fill("")
        .map((_viewportStatus, index) => {
          if (state.viewportStatuses[index] !== undefined) {
            return state.viewportStatuses[index];
          }
          return VIEWPORT_STATUSES.INITIALIZING;
        });
      const updatedFrameIndexArray = new Array(
        updatedGridDimensions[0] * updatedGridDimensions[1]
      )
        .fill(0)
        .map((item, index) => {
          if (state.viewportFrameIndexes[index] !== undefined) {
            return state.viewportFrameIndexes[index];
          }
          return item;
        });

      return {
        ...state,
        gridDimensions: action.payload.updatedGridDimensions,
        viewports: updatedViewports,
        ViewportStatuses: updatedStatuses,
        viewportFrameIndexes: updatedFrameIndexArray,
      };
    }

    case "SAVE_STUDY_METADATA": {
      const { studyImageIndex, studyImageMetaData, rawMetaData } =
        action.payload;
      const updatedStudyImageMetaData = state.studyImageMetaData.map(
        (metaData, index) =>
          index === studyImageIndex ? studyImageMetaData : metaData
      );
      const rawData = state.rawMetaData.map((meta, index) =>
        index === studyImageIndex ? rawMetaData : meta
      );
      return {
        ...state,
        studyImageMetaData: updatedStudyImageMetaData,
        rawMetaData: rawData,
      };
    }
    case "SET_IMAGE_FRAME_INDEX": {
      const { viewportIndex, frameIndex } = action.payload;
      const updatedFrameIndexes = state.viewportFrameIndexes.map(
        (viewportFrameIndex, index) =>
          index === viewportIndex ? frameIndex : viewportFrameIndex
      );

      return {
        ...state,
        viewportFrameIndexes: updatedFrameIndexes,
      };
    }
    case "SCRUB_IMAGE_FRAME_INDEX": {
      const { direction } = action.payload;
      const { viewportFrameIndexes, viewports, isMPRActive } = state;
      const { updatedFrameIndexes, updatedMPRCoordinates } = updateFrameIndexes(
        viewportFrameIndexes,
        viewports,
        direction,
        isMPRActive
      );
      return {
        ...state,
        viewportFrameIndexes: updatedFrameIndexes,
        mprCoordinates: updatedMPRCoordinates,
      };
    }
    case "CHANGE_ACTIVE_VIEWPORT": {
      const updatedViewports = state.viewports.map((viewport, index) =>
        index === 0 ? action.payload.newViewport : viewport
      );
      const updatedViewportStatuses = state.viewportStatuses.map(
        (status, index) =>
          index === 0 ? VIEWPORT_STATUSES.INITIALIZING : status
      );

      return {
        ...state,
        viewportStatuses: updatedViewportStatuses,
        viewports: updatedViewports,
      };
    }
    case "UPDATE_VIEWPORT_IMAGE_DATA": {
      const { viewportIndex, viewportData } = action.payload;
      const updatedViewports = state.viewports.map((viewport, index) =>
        viewportIndex === index ? viewportData : viewport
      );
      const viewportStatuses = state.viewportStatuses.map(
        (viewportStatus, index) =>
          index === viewportIndex
            ? VIEWPORT_STATUSES.INITIALIZING
            : viewportStatus
      );
      return {
        ...state,
        viewportStatuses,
        viewports: updatedViewports,
      };
    }
    case "SET_VIEWPORT_STATUS": {
      const { viewportIndex, newStatus } = action.payload;
      const updatedViewportStatuses = state.viewportStatuses.map(
        (viewport, index) => (viewportIndex === index ? newStatus : viewport)
      );
      return {
        ...state,
        viewportStatuses: updatedViewportStatuses,
      };
    }
    case "SET_MPR_VIEWPORT_STATUSES": {
      const { updatedStatus } = action.payload;
      return {
        ...state,
        viewportStatuses: [
          state.viewportStatuses[0],
          updatedStatus,
          updatedStatus,
        ],
      };
    }

    default:
      console.warn(`Reducer has not been configured for ${action.type}`);
      return state;
  }
};

const ViewerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_VIEWER_STATE);
  return (
    <ViewerContext.Provider value={{ state, dispatch }}>
      {children}
    </ViewerContext.Provider>
  );
};

const useViewerContext = () => {
  const context = useContext(ViewerContext);
  if (!context) {
    throw new Error("useViewerContext must be used within a Viewer Provider");
  }

  return context;
};

export { ViewerProvider, useViewerContext };
